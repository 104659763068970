import axiosRetry from 'axios-retry';
import { t } from 'i18next';
import iziToast from 'izitoast';
import queryString from 'query-string';
import { generatePath } from 'react-router-dom';

import type { IAxiosRetryConfigExtended } from 'axios-retry';

import ApiConfig from './ApiConfig';
import EnvSettings from './EnvSettings';
import SocketInstance from 'src/realTimeNotifications';
import { apiClient } from 'src/Utilities/httpClients';

import type { TicketArchivalUploadData } from '../types/Archival';
import type { ImportTemplate, UploadedFileData } from 'src/types/Campaign';
import type { Channel } from 'src/types/Channel';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { FormattedSearch } from 'src/types/Search';
import type { Tag } from 'src/types/Tag';
import type { Comment, Entity, Ticket, TicketListTicket } from 'src/types/Ticket';
import type { CaseType } from 'src/types/TicketList';
import type { TicketType } from 'src/types/TicketType';
import type { TitleTemplate } from 'src/types/TitleTemplate';

export interface UpdateEntityDetail {
  entityId: string;
  entityType: string;
  fieldName: string;
  object: string;
  partial: boolean;
  valueToSave: any;
}

export default class TicketsApi {
  static getTickets = async (searchParams: { searchterms: FormattedSearch }): Promise<TicketListTicket[]> => {
    if (searchParams.searchterms.basic !== undefined) {
      searchParams.searchterms.basic.type = ['task'];
      return TicketsApi.searchTickets<TicketListTicket>(searchParams);
    } else {
      return apiClient.get('/casesWithData').then((response) => {
        SocketInstance.resendMessagesAfterTimestamp(parseInt(response.headers['cache-creation'], 10));

        return response.data;
      });
    }
  };

  static getTicketTypes = async (): Promise<TicketType[]> => {
    return apiClient.get('/ticketTypes').then((response) => response.data);
  };

  static getResponseTemplates = async (): Promise<ResponseTemplate[]> => {
    return apiClient.get('/responseTemplates').then((response) => response.data);
  };

  static getTitleTemplates = async (): Promise<TitleTemplate[]> => {
    return apiClient.get('/titleTemplates').then((response) => response.data);
  };

  static getChannelTypes = async (): Promise<Channel[]> => {
    return apiClient.get('/channelTypes').then((response) => response.data);
  };

  static updateGiosgWritingStatus = async (chat_id: string, agentName: string, room_id: string): Promise<Channel[]> => {
    return apiClient.put('/chats/status/giosg', { chat_id, agentName, room_id }).then((response) => response.data);
  };

  static updateChatTypingStatusToCustomer = (connectionId: string): Promise<Channel[]> => {
    return apiClient.put('/chats/status/elisachat', { connectionId }).then((response) => response.data);
  };

  static searchTickets = async <T = Ticket>(searchParams: { searchterms: FormattedSearch }): Promise<T[]> => {
    if (searchParams.searchterms.basic.selectedTicketTagsAnd && !searchParams.searchterms.basic.searchToggleAndOr) {
      // inclusive tag search; disable searching for default tags in order not to get all content
    } else {
      searchParams.searchterms.basic.orTags = ApiConfig.getConfig().API_MAIN_CONTENT_TAGS;
    }

    return apiClient
      .post('/search', {
        ...searchParams
      })
      .then((response) => {
        if (response.data.length >= EnvSettings.getSettings().MAX_SEARCH_RESULTS) {
          iziToast.warning({
            timeout: 0,
            close: false,
            overlay: true,
            id: 'question',
            zindex: 999,
            message: `${t('MAX_SEARCH_LIMIT', {
              limit: EnvSettings.getSettings().MAX_SEARCH_RESULTS
            })}.`,
            position: 'center',
            buttons: [
              [
                `<button><b>${t('GENERAL_CLOSE')}</b></button>`,
                (instance: any, toast: any) => {
                  instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
                },
                true
              ]
            ]
          });

          return response.data;
        } else {
          return response.data;
        }
      })
      .catch(() => {
        return [{ id: 'Error' }];
      });
  };

  static getTicketsByEntity = async (
    entityId: string,
    entityType: string,
    caseType: CaseType = 'task'
  ): Promise<TicketListTicket[]> => {
    const query = queryString.stringify({
      entityType,
      caseType
    });

    return apiClient.get(`/cases/entities/${entityId}?${query}`).then((response) => response.data);
  };

  static searchEntityByDetails = async (params: any) => {
    const searchUri = queryString.stringify(params);
    return apiClient.get(`/entities/search?mode=approximate&${searchUri}`).then((response) => response.data);
  };

  static getTicket = async (id: string, queryParams?: { [key: string]: any }): Promise<Ticket> => {
    const query = queryParams ? `?${queryString.stringify(queryParams)}` : '';
    return apiClient
      .get(generatePath('/case/:id', { id: id.substring(3) }) + query, {
        timeout: 15000
      })
      .then((response) => response.data);
  };

  static getTicketComments = async (id: string): Promise<Comment[]> => {
    return apiClient
      .get(generatePath('/case/:id/comments', { id: id.substring(3) }), {
        timeout: 30000
      })
      .then((response) => response.data);
  };

  static makeTicket = async (task: any): Promise<Ticket> => {
    return apiClient
      .post('/cases/', {
        title: task.title,
        type: task.type,
        content: task.content,
        channel: task.channel || 4,
        priority: task['priority'] || 0,
        originalDirection: task.originalDirection || task.direction || 'out',
        dueDate: task.dueDate,
        status: task.status,
        lastContact: task.lastContact,
        tagIds: task.tags ? task.tags : ApiConfig.getConfig().API_MAIN_CONTENT_TAGS,
        delegatedToUserIds: task.delegatedTo,
        taskType: task.taskType,
        ocTicketId: task.case.ocTicketId || 0,
        originalContact: task.originalContact,
        lastContactAddress: task.originalContact
      })
      .then((response) => {
        response.data.case = {};
        response.data.product = {};
        return response.data;
      });
  };

  static fetchLinkedTickets = async (ticketId: string): Promise<Ticket[]> => {
    return apiClient.get('/case/' + ticketId.substring(3) + '/linkedTickets').then((response) => response.data);
  };

  static addRelatedTicketToTicket = async (id: string, targetId: any, type: string): Promise<Ticket[]> => {
    return apiClient
      .post('/case/' + id.substring(3) + '/linkedTickets', {
        targetId: targetId,
        type: type
      })
      .then((response) => response.data);
  };

  static removeTicketLinking = (id: string, targetId: any): Promise<Ticket[]> => {
    return apiClient.delete('/case/' + id + '/linkedTickets/' + targetId).then((response) => response.data);
  };

  static updateMetadata = async (id: number | string, metadata: object): Promise<any> => {
    return apiClient
      .patch('/cases/' + id, {
        metaData: JSON.stringify(metadata)
      })
      .then((response) => response.data);
  };

  static updateTicket = async (id: string, task: Partial<Ticket>): Promise<TicketListTicket> => {
    return apiClient
      .patch('/cases/' + id, {
        title: task.title,
        content: task.content,
        dueDate: task.dueDate,
        status: task.status,
        taskType: task.taskType,
        channel: task.channel,
        priority: task.priority,
        weight: task.weight
      })
      .then((response) => response.data);
  };

  static updateSingleTicketDetail = async (ticketId: string, updateKey: string, updateValue: any, group: string) => {
    return apiClient
      .put(generatePath('/case/:id/details/:updateKey?', { id: ticketId.substring(3), updateKey }), {
        group: group,
        type: updateKey,
        value: updateValue
      })
      .then((response) => response.data);
  };

  static updateTicketDetails = async (ticketId: string, group: string, items: { type: string; value: any }[]) => {
    return apiClient
      .put(generatePath('/case/:id/details/:updateKey?', { id: ticketId.substring(3) }), {
        group,
        items
      })
      .then((response) => response.data);
  };

  static searchContentDetails = async (details: { [key: string]: unknown }) => {
    // There may be a situation where the ticket has not been created yet, and we get a 404,
    // so we try to wait and fetch again
    const axiosRetryConfig: IAxiosRetryConfigExtended = {
      retries: 5,
      retryCondition: (error) => {
        const isNetworkOrIdempotentRequestError = axiosRetry.isNetworkOrIdempotentRequestError(error);
        return isNetworkOrIdempotentRequestError || error.response?.status === 404;
      }
    };

    return apiClient
      .post('/cases/searchByDetail?returnErrorIfEmpty=true', details, { 'axios-retry': axiosRetryConfig })
      .then((response) => response.data)
      .catch((error) => {
        if (error.response?.status === 404) {
          return [];
        }
        throw error;
      });
  };

  static updateEntityDetail = async ({
    taskType,
    updateArgs
  }: {
    taskType?: string;
    updateArgs: UpdateEntityDetail;
  }): Promise<any> => {
    const { valueToSave, partial, object, fieldName, entityType, entityId } = updateArgs;
    let updateValueObj = valueToSave;
    const updateKeyNew = partial ? object : fieldName;

    const updateURL = `/entities/${entityType}/${entityId}/${updateKeyNew}`;
    if (partial) {
      updateValueObj = {};
      updateValueObj[fieldName] = valueToSave;
    }
    return apiClient
      .put(updateURL, {
        type: updateKeyNew,
        value: updateValueObj,
        partial,
        taskType
      })
      .then((response) => response.data);
  };

  static addComment = async (ticketId: string, data: object): Promise<Comment> => {
    const query = `?${queryString.stringify({ type: ticketId.substring(0, 3) })}`;

    return apiClient
      .post(generatePath('/cases/:id/comments', { id: ticketId.substring(3) }) + query, {
        ...data
      })
      .then((response) => response.data);
  };

  static addDelegateToContent = async (ticketId: string, userId: string): Promise<any> => {
    return apiClient
      .post(`/cases/${ticketId.substring(3)}/delegatedTo/${userId}`, {
        type: ticketId.substring(0, 3)
      })
      .then((response) => response.data);
  };

  static removeDelegateFromContent = async (ticketId: string, userId: string): Promise<any> => {
    return apiClient
      .delete(`/cases/${ticketId.substring(3)}/delegatedTo/${userId}`, {
        data: { type: ticketId.substring(0, 3) }
      })
      .then((response) => response.data);
  };

  static addGroupDelegateToContent = async (ticketId: string, userGroup: number[]): Promise<any> => {
    return apiClient
      .post(`/cases/${ticketId.substring(3)}/groupDelegatedTo/`, {
        userGroup: userGroup
      })
      .then((response) => response.data);
  };

  static removeGroupDelegateFromContent = async (ticketId: string, userGroup: number[]): Promise<any> => {
    return apiClient
      .delete(`/cases/${ticketId.substring(3)}/groupDelegatedTo`, { data: { userGroup } })
      .then((response) => response.data);
  };

  static archiveTicket = async (ticketId: number, ticketArchivalData: TicketArchivalUploadData): Promise<any> => {
    return apiClient.post(`/cases/${ticketId}/archive`, ticketArchivalData).then((response) => response.data);
  };

  static addTagToContent = async (ticketId: string, tagId: string, addnew: boolean): Promise<any> => {
    return apiClient
      .post(`/cases/${ticketId.substring(3)}/tags/${tagId}`, {
        addnew,
        type: ticketId.substring(0, 3)
      })
      .then((response) => response.data);
  };

  static removeTagFromContent = async (ticketId: string, userId: string): Promise<any> => {
    return apiClient.delete(`/cases/${ticketId.substring(3)}/tags/${userId}`, {
      data: {
        type: ticketId.substring(0, 3)
      }
    });
  };

  static addEntityToCase = async (ticketId: string, body: Entity): Promise<any> => {
    const { _id, ...restProps } = body;
    const payload = !_id ? restProps : { _id, ...restProps };

    return apiClient.post(`/cases/${ticketId}/entities`, payload).then((response) => response.data);
  };

  static removeEntityFromCase = async (
    ticketId: string,
    body: {
      _id: string;
      _type: string;
    }
  ): Promise<any> => {
    const url = `/cases/${ticketId}/entities/${body._id}`;
    return apiClient
      .delete(url, {
        data: body
      })
      .then((response) => response.data);
  };

  static getTags = async (): Promise<Tag[]> => {
    return apiClient.get('/tags/').then((response) => response.data);
  };

  static getCampaignTemplates = async (): Promise<ImportTemplate[]> => {
    return apiClient.get('/caseImportTemplates/').then((response) => response.data);
  };

  static addCampaignTemplate = async (template: ImportTemplate['templateData']): Promise<ImportTemplate[]> =>
    apiClient.post('/caseImportTemplates/', template).then((response) => response.data);

  static uploadFile = async (file: FormData, uploadType = 'attachment'): Promise<UploadedFileData> => {
    return apiClient.post(`/upload/${uploadType}`, file).then((response) => response.data);
  };

  static attachAttachmentToCase = async (attachmentId: string, ticketId: string, secret: string): Promise<any> => {
    return apiClient
      .post(`/cases/${ticketId}/attachments/${attachmentId}`, {
        secret
      })
      .then((response) => response.data);
  };

  static editAttachment = async (ticketId: string, attachmentId: string, body: any): Promise<any> => {
    return apiClient
      .patch(`/cases/${ticketId.substring(3)}/attachments/${attachmentId.substring(3)}`, body)
      .then((response) => response.data);
  };

  static deprecateAttachment = async (ticketId: string, attachmentId: string): Promise<any> => {
    return apiClient.put(`/cases/${ticketId}/attachments/${attachmentId}`, {}).then((response) => response.data);
  };

  static unDeprecateAttachment = async (ticketId: string, attachmentId: string): Promise<any> => {
    return apiClient.delete(`/cases/${ticketId}/attachments/${attachmentId}`, {}).then((response) => response.data);
  };

  static startWorkingOn = async (ticketId: number, UID: number) => {
    return apiClient.post(`/cases/${ticketId}/workstatus`, {
      UID
    });
  };

  static replaceWorkingOn = async (ticketId: number, previousUID: number, nextUID: number) => {
    return apiClient.patch(`/cases/${ticketId}/workstatus`, {
      previousUID,
      nextUID
    });
  };

  static stopWorkingOn = async (ticketId: number, UID: number) => {
    return apiClient.delete(`/cases/${ticketId}/workstatus`, {
      data: { UID }
    });
  };

  static fetchTicketPriorities = async () => {
    return apiClient.get(`/ticketPriorities`).then((response) => response.data);
  };

  static mergeTicketsInto = async (mainTicketId: string, ticketsToMerge: string[]) =>
    apiClient
      .post(generatePath('/case/:id/merge', { id: mainTicketId }), ticketsToMerge)
      .then((response) => response.data);
}
